import { EditorSDK } from '@wix/platform-editor-sdk';

import { MA_PUBLIC_API_NOT_FOUND_ERROR, uninstallProfilePageBoB } from './members-area-page';

export const uninstallMembersArea = async (editorSDK: EditorSDK) => {
  try {
    await uninstallProfilePageBoB(editorSDK);
  } catch (e) {
    // No need to throw an error if the app is not installed
    if ((e as Error).message === MA_PUBLIC_API_NOT_FOUND_ERROR) {
      return;
    }
    throw e;
  }

  await editorSDK.application.uninstall('', { openConfirmation: false });
};
