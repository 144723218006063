import { ContextParams, EditorReadyFn, EditorSDK, GetAppManifest } from '@wix/platform-editor-sdk';

import { initializeMonitoring } from '../utils/monitoring';
import { maybeConductExperiments, maybeConductV2Experiments } from '../utils/experiments';
import { parseStaticsUrlFromEditorScriptUrl } from './services/urls';
import * as applicationState from './services/applicationState';
import { currentMembersArea, exportsFn, getControllerPresets, membersAreaOnMSB } from './platform-app';
import { MembersAreaOnEventFn } from './platform-app/types';
import { getIsMembersAreaV2 } from './services/context';

const getEditorPlatformApp = async (editorSDK: EditorSDK, options?: ContextParams) => {
  const isMembersAreaV2 = await getIsMembersAreaV2(editorSDK, options);
  return isMembersAreaV2 ? membersAreaOnMSB : currentMembersArea;
};

const editorReady: EditorReadyFn = async (editorSDK, _appToken, options) => {
  maybeConductExperiments();
  maybeConductV2Experiments();

  applicationState.setEditorSDK(editorSDK);
  applicationState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl));
  // @ts-expect-error - blogWriterProfilesOnly does not exist on Origin.info type in Editor platform sdk types
  applicationState.setIsBlogWriterProfilesOnly(!!options.origin.info?.blogWriterProfilesOnly);
  applicationState.setIsClassicEditor(options.origin.type === 'CLASSIC');
  applicationState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE');
  applicationState.setIsADI(options.origin.type.indexOf('ADI') === 0);

  try {
    await initializeMonitoring(editorSDK, options);
  } catch (e) {}

  const platformApp = await getEditorPlatformApp(editorSDK, options);
  return platformApp.editorReady(editorSDK, _appToken, options);
};

const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const platformApp = await getEditorPlatformApp(editorSDK);
  return platformApp.onEvent(event, editorSDK);
};

const getAppManifest: GetAppManifest = async (options, editorSDK, contextParams) => {
  const platformApp = await getEditorPlatformApp(editorSDK);
  return platformApp.getAppManifest(options, editorSDK, contextParams);
};

export { editorReady, onEvent, getAppManifest, getControllerPresets, exportsFn as exports };
