import { ContextParams, EditorSDK } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

import { getGlobalMembersAreaController } from '../wrappers/controllers';
import { shouldUseMembersAreaOnBlocksInstallFlow } from '../../utils/experiments';
import { isApplicationInstalled } from '../wrappers/application';

declare const self: {
  commonConfig: {
    brand: string;
  };
};

export const getIsMembersAreaV2 = async (editorSDK: EditorSDK, options?: ContextParams) => {
  if (self?.commonConfig?.brand === 'studio') {
    return true;
  }

  if (options?.firstInstall) {
    return shouldUseMembersAreaOnBlocksInstallFlow();
  }

  return isApplicationInstalled(editorSDK, MEMBERS_AREA_V2);
};

export const getIsMembersAreaSeoEnabled = async (editorSDK: EditorSDK) => {
  try {
    const controllerConfig = await getGlobalMembersAreaController(editorSDK);

    return !!controllerConfig?.isMembersAreaSeoEnabled;
  } catch (e) {
    return false;
  }
};
