import Experiments from '@wix/wix-experiments';

let conductedExperiments: Experiments;
let conductedExperimentsV2: Experiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function maybeConductV2Experiments() {
  if (!conductedExperimentsV2) {
    conductedExperimentsV2 = new Experiments({ scope: 'members-area-v2' });
  }
  await conductedExperimentsV2.ready();
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function shouldFixCorruptedRouterPatterns() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.FixCorruptedRouterPatterns') === 'true';
}

async function shouldDeleteMobileComponents() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.handleMobileComponentsDeletion') === 'true';
}

async function shouldFixLoginBarResponsiveLayout() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.fixLoginBarResponsiveLayout') === 'true';
}

async function shouldEnableSignUpPrivacyNoteType() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.ident.EnableSignUpPrivacyNoteType') === 'true';
}

async function shouldUseMembersAreaOnBlocksInstallFlow() {
  await Promise.all([maybeConductExperiments(), maybeConductV2Experiments()]);

  return (
    conductedExperiments.get('specs.membersArea.profilePageBoBInstallation') === 'true' ||
    conductedExperimentsV2.get('specs.membersAreaV2.BoBInstallation') === 'true'
  );
}

async function shouldEnableTimeoutLogs() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.enableTimeoutLogs') === 'true';
}

async function shouldAllowDeletionOfBrokenApp() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AllowDeletionOfBrokenApp') === 'true';
}

async function shouldNotAllowStartAfterStop() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.NoStartAfterStop') === 'true';
}

async function shouldEnableSeoPanelChanges() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.seoPanelChanges') === 'true';
}

async function shouldUseNewAppInstallCheck() {
  try {
    await maybeConductExperiments();
  } catch {}

  return conductedExperiments.get('specs.membersArea.UseNewAppInstallCheck') === 'true';
}

async function shouldRemoveMembersAreaV2UnsuccessfulInstall() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.v2UninstallUnsuccessfulInstall') === 'true';
}

async function shouldVerifyMemberPage() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.verifyMemberPage') === 'true';
}

async function shouldEnableCustomPagesInV2() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersAreaV2.enableCustomPages') === 'true';
}

async function shouldRemoveGlobalControllerIfItExists() {
  try {
    await maybeConductExperiments();
    return conductedExperiments.get('specs.membersArea.removeGlobalControllerOnFirstInstall') === 'true';
  } catch (e) {}
}

async function shouldAddLoginBarMenuRefsToDefinitions() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.LoginBarAddMenuRefsToDefinitions') === 'true';
}

async function shouldUseNewRoutesService() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.UseNewRoutesService') === 'true';
}

export {
  maybeConductExperiments,
  maybeConductV2Experiments,
  isADIHorizontalLayoutEnabled,
  shouldFixCorruptedRouterPatterns,
  shouldDeleteMobileComponents,
  shouldFixLoginBarResponsiveLayout,
  shouldEnableSignUpPrivacyNoteType,
  shouldUseMembersAreaOnBlocksInstallFlow,
  shouldEnableTimeoutLogs,
  shouldAllowDeletionOfBrokenApp,
  shouldNotAllowStartAfterStop,
  shouldEnableSeoPanelChanges,
  shouldUseNewAppInstallCheck,
  shouldRemoveMembersAreaV2UnsuccessfulInstall,
  shouldVerifyMemberPage,
  shouldRemoveGlobalControllerIfItExists,
  shouldEnableCustomPagesInV2,
  shouldAddLoginBarMenuRefsToDefinitions,
  shouldUseNewRoutesService,
};
