import { ComponentDefinition, ComponentRef, EditorSDK, PageRef } from '@wix/platform-editor-sdk';

import * as constants from '../constants';
import { CONTROLLER_COMP_CUSTOM_ID } from '../constants';
import { log } from '../../utils/monitoring';
import * as componentsWrapper from './components';
import { getById } from './components';
import * as appState from '../services/applicationState';
import { GlobalMembersAreaController } from '../../types/controllers';

const { APP_TOKEN } = constants;

async function createController(editorSDK: EditorSDK, masterRef: PageRef) {
  // todo - validate - "there can be only one"
  const compDef = constants.CONTROLLER_COMP_DEF as unknown as ComponentDefinition;
  return editorSDK.components.add(APP_TOKEN, {
    pageRef: masterRef,
    componentDefinition: compDef,
    customId: constants.CONTROLLER_COMP_CUSTOM_ID,
  });
}

async function getController(editorSDK: EditorSDK) {
  const controllers = await editorSDK.controllers.listControllers(APP_TOKEN, {});
  const globalController = controllers.find((c) => c?.controllerRef.id === constants.CONTROLLER_COMP_CUSTOM_ID);
  return globalController?.controllerRef as ComponentRef;
}

async function getControllerConfig(editorSDK: EditorSDK, id: string) {
  const controllerRef = await getById({ editorSDK, id });
  if (controllerRef) {
    const controllerData = await editorSDK.controllers.getData(APP_TOKEN, { controllerRef });
    return controllerData.config;
  }
}

async function getGlobalControllerRef(editorSDK: EditorSDK) {
  return getById({ editorSDK, id: CONTROLLER_COMP_CUSTOM_ID });
}

async function getGlobalMembersAreaController(editorSDK: EditorSDK): Promise<GlobalMembersAreaController> {
  return getControllerConfig(editorSDK, CONTROLLER_COMP_CUSTOM_ID);
}

async function getAllControllers(editorSDK: EditorSDK) {
  return editorSDK.controllers.listAllControllers(APP_TOKEN) as Promise<{ controllerRef: ComponentRef }[]>;
}

function filterDuplicateControllers(controllerList: { controllerRef: ComponentRef }[]) {
  const isUniqueController = (
    ref: { controllerRef: ComponentRef },
    idx: number,
    arr: { controllerRef: ComponentRef }[],
  ) => arr.findIndex((ref2) => ref2.controllerRef.id === ref.controllerRef.id) === idx;
  return controllerList.filter(isUniqueController);
}

async function getAllMasterControllers(editorSDK: EditorSDK) {
  const allControllersRefs = await getAllControllers(editorSDK);

  const masterControllersRefs = await Promise.all(
    allControllersRefs.map(async ({ controllerRef }) => {
      const isSlave = controllerRef.id.includes('_r_');
      if (isSlave) {
        const templateControllerRef = await editorSDK.components.refComponents.getTemplateComponent(APP_TOKEN, {
          componentRef: controllerRef,
        });
        return { controllerRef: templateControllerRef };
      } else {
        return { controllerRef };
      }
    }),
  );
  const uniqueMasterControllersRefs = filterDuplicateControllers(masterControllersRefs);

  return uniqueMasterControllersRefs;
}

async function removeConnectedComponents(editorSDK: EditorSDK, controllerRef: { controllerRef: ComponentRef }) {
  const componentsRefs = await editorSDK.controllers.listConnectedComponents(APP_TOKEN, controllerRef);
  for (const componentRef of componentsRefs) {
    await editorSDK.components.remove(APP_TOKEN, { componentRef });
  }
}

async function remove(editorSDK: EditorSDK, controllerRef: { controllerRef: ComponentRef }) {
  await editorSDK.components.remove(APP_TOKEN, { componentRef: controllerRef.controllerRef });
}

async function wipeOut(editorSDK: EditorSDK) {
  const controllersRefs = await getAllMasterControllers(editorSDK);
  for (const controllerRef of controllersRefs) {
    // To do: report a bug for editor platform with controllers deletion
    // We get an error that they're already being deleted in uninstallation, but they stay if we don't delete them and crash further installations
    if (appState.getIsResponsiveEditor()) {
      try {
        await removeConnectedComponents(editorSDK, controllerRef);
        await remove(editorSDK, controllerRef);
      } catch (e) {
        log(
          'An error occured when deleting controllers in EditorX. However they are expected to be deleted by the platform',
          { extra: { e } },
        );
      }
    } else {
      await removeConnectedComponents(editorSDK, controllerRef);
      await remove(editorSDK, controllerRef);
    }
  }

  const appDefIdsToRemove = [constants.PROFILE_WIDGET_APP.appDefinitionId, constants.ALL_MEMBERS_APP_DEF_ID];
  await componentsWrapper.removeComponentsByAppDefIds(editorSDK, appDefIdsToRemove);
}

async function connectToController({
  editorSDK,
  connectToRef,
  controllerRef,
  role,
  connectionConfig = {},
  isPrimary = true,
}: {
  editorSDK: EditorSDK;
  connectToRef: ComponentRef;
  controllerRef: ComponentRef;
  role: string;
  connectionConfig?: object;
  isPrimary?: boolean;
}) {
  try {
    await editorSDK.controllers.connect(APP_TOKEN, {
      connectToRef,
      controllerRef,
      connectionConfig,
      role,
      isPrimary,
    });
  } catch (e) {
    const tags = { platformMessage: (e as Error).toString() };
    const extra = { args: JSON.stringify({ connectToRef, controllerRef, role, connectionConfig, isPrimary }) };
    log('Failed to connect to controller', { tags, extra });
    throw e;
  }
}

export {
  connectToController,
  createController,
  getController,
  wipeOut,
  getAllControllers,
  removeConnectedComponents,
  getControllerConfig,
  getGlobalMembersAreaController,
  getGlobalControllerRef,
};
